<template>
  <div>
    <a-modal
        :width="800"
        v-model="show"
        :title="shamTitle"
        ok-text="确认"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item label="假币类型">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              style="margin: 5px; width: 180px;"
              v-model="params.shamType"
              placeholder="假币类型"
              @click="handleChangeShamType"
          >
            <a-select-option
                v-for="items of shamTypeList"
                :key="items.value"
                :value="items.value"
            >{{ items.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="shamNameText">
          <a-input
              v-model="params.shamName"
              :placeholder="params.shamType ? '名称' : '请先选择假币类型'"
          />
        </a-form-model-item>
        <a-form-model-item label="大币种" v-if="[10].includes(params.shamType)">
          <a-select
              :dropdownMatchSelectWidth="false"
              :filter-option="untils.filterOption"
              :dropdownMenuStyle="{'max-height': '350px'}"
              allowClear
              showSearch
              @search='searchData'
              style="margin: 5px; width: 180px;"
              v-model="params.coinId"
              placeholder="请选择大币种"
          >
            <a-select-option
                v-for="items of bigCoinList"
                :key="items.id"
                :value="items.id"
            >{{ items.coinKindName + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="评级公司" v-if="[1, 20].includes(params.shamType)">
          <a-select
              allowClear
              v-model="params.companyType"
              style="width: 250px;"
              placeholder='评级公司'
          >
            <a-select-option
                v-for="item in ratingCompanyData"
                :key="item.id"
                :value='item.id'
            >{{ item.value }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="codeTypeText" v-if="params.shamType === 1">
          <a-input
              style="width: 250px;"
              v-model="params.codeBatchMatch"
              :placeholder="params.shamType ? '号段' : '请先选择假币类型'"
          />
        </a-form-model-item>
        <a-form-item label="图片">
          <a-upload
              :key="imgKey"
              :action="ip + '/file/upload'"
              list-type="picture-card"
              :file-list="coverList"
              :multiple="true"
              @preview="handlePreview"
              @change="handleChangeCoverList"
          >
            <div v-if="coverList.length < 15">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-model-item label="备注描述">
          <a-textarea
              v-model="params.remark"
              placeholder="备注描述"
              :rows="4" />
        </a-form-model-item>
<!--   另一个对象     -->
        <a-divider v-if="!shamId && [10, 20].includes(params.shamType)">以下为{{ shamTypeText[params.shamType] }}盒子快捷单编号录入</a-divider>
        <a-form-model-item label="评级公司" v-if="!shamId && [10].includes(params.shamType)">
          <a-select
              allowClear
              v-model="params.boxCompanyType"
              style="width: 250px;"
              placeholder='评级公司'
          >
            <a-select-option
                v-for="item in ratingCompanyData"
                :key="item.id"
                :value='item.id'
            >{{ item.value }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :label="codeTypeText" v-if="!shamId && [10, 20].includes(params.shamType)">
          <a-input
              style="width: 250px;"
              v-model="params.boxCode"
              :placeholder="params.shamType ? '号段' : '请先选择假币类型'"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import untils from "@/untils";
import {ratingCompanyData} from "@/views/cmsPage/productManage/_data";
import {getBigCoinList} from "@/axios/apis"
export default {
  props: ["detail"],
  data() {
    return {
      untils: untils,
      show: false,
      ratingCompanyData: ratingCompanyData.slice(1),
      shamTypeText: {
        10: '同模假',
        20: '真盒假币',
        50: '性质假'
      },
      shamTypeList: [
        {value: 1, name: '批量假'},
        {value: 10, name: '同模假'},
        {value: 20, name: '真盒假币'},
        {value: 50, name: '性质假'},
      ],
      params: {
        id: undefined,
        // 假币类型
        shamType: undefined,
        // 假币名称
        shamName: undefined,
        // 图片
        images: undefined,
        // 币种ID
        coinId: undefined,
        // 号段
        boxCode: undefined,
        // 评级公司
        boxCompanyType: undefined,
        // 描述
        remark: undefined,
        // 批量假号段
        codeBatchMatch: undefined,
        // 批量假评级公司
        companyType: undefined
      },
      previewVisible: false,
      previewImage: "",
      coverList: [],
      fileList: [],
      imgKey: "",
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      rules: {
        tagName: [
          { required: true, message: "名称不能为空", trigger: "change" },
        ],
      },
      shamTitle: '新增假币类型管理',
      shamId: '',
      bigCoinList: [],
      isLoading: false
    };
  },
  computed: {
    ...mapState(["ip"]),
    shamNameText() {
      if (this.params.shamType === 1) {
        return '批量假名称'
      } else if (this.params.shamType === 10) {
        return '同模假名称'
      } else if (this.params.shamType === 20) {
        return '真盒假币名称'
      } else {
        return '选择类型'
      }
    },
    codeTypeText() {
      switch (this.params.shamType) {
        case 20:
          return '单编号'
        case 10:
          return '单编号'
        case 1:
          return '批量编号'
        default:
          return '选择假币类型'
      }
    }
  },
  mounted() {
    addEventListener("paste", (params) => {
      this.untils.pasteImg(params, 1, (a, b, s) => {
        this.coverList.push({
          uid: this.coverList.length + 1,
          name: "image",
          status: "done",
          url: b,
        });
      });
    });
  },
  methods: {
    async showPopup(data) {
      this.resetForms()
      this.coverList = []
      if (data) {
        this.shamId = data.id
        this.shamTitle = '编辑假币类型管理'
        this.$loading.show()
        await this.searchData(data.coinName)
        await this.setDetail(data)
        this.$loading.hide()
      }
      this.show = true
    },
    setDetail(data) {
      this.params = Object.assign(this.params, data)
      if (this.params.images) {
        const tempUrlList = this.params.images.split(',')
        this.coverList = tempUrlList.map((items, i) => {
          const obj = {
            uid: i,
            name: "image",
            status: "done",
            url: items,
          };
          return obj;
        });
      }
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = JSON.parse(JSON.stringify(fileList));
    },
    handleChangeCoverList({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.coverList = fileList;
    },
    previewImgs() {
      const picturesList = this.fileList.map((row) => {
        return row.url;
      });
      if (picturesList && picturesList.length > 0) {
        this.$previewImg({
          list: picturesList.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    handlePreview(file, type) {
      this.$previewImg({
        list: [{img_url: `${file.url}`}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    hideModal() {
      if (this.isLoading) return this.$message.warn('请勿重复提交')
      if (!this.params.shamType) return this.$message.warn('假币类型不能为空')
      if (!this.params.shamName) return this.$message.warn('名称不能为空')
      if (this.params.shamType === 1) {
        if (!this.params.companyType) return this.$message.warn('评级公司不能为空')
        if (!this.params.codeBatchMatch) return this.$message.warn('批量号段不能为空')
      }
      if (this.params.shamType === 10) {
        if (!this.params.coinId) return this.$message.warn('大币种不能为空')
      }
      if (this.params.shamType === 10) {
        if (this.coverList.length <= 0) return this.$message.warn('图片必须上传图片')
      }
      if (this.params.boxCompanyType || this.params.boxCode) {
        if (this.params.shamType === 10) {
          if (!this.params.boxCompanyType) return this.$message.warn('评级公司不能为空')
        }
        if (!this.params.boxCode) return this.$message.warn('单个号段不能为空')
      }
      const abs = this.coverList.map((row) => {
        return row.url;
      });
      this.params.images = abs.join(",");
      this.submit()
    },
    async submit() {
      let boxShamType = ''
      let boxBase = ''

      boxShamType = {
        id: this.params.id,
        shamType: this.params.shamType,
        companyType: this.params.companyType,
        codeBatchMatch: this.params.codeBatchMatch,
        shamName: this.params.shamName,
        images: this.params.images,
        coinId: this.params.coinId,
        remark: this.params.remark
      }
      boxBase = {
        boxCode: this.params.boxCode,
        boxCompanyType: this.params.shamType === 20 ? this.params.companyType : this.params.boxCompanyType,
      }

      const sendObj = {
        boxShamType,
        boxBase
      }
      if (this.params.shamType === 10 && !this.params.boxCode && !this.params.boxCompanyType) {
        delete sendObj.boxBase
      }
      if (this.params.shamType === 10) {
        delete boxShamType.companyType
        delete boxShamType.codeBatchMatch
      }
      if (this.params.shamType === 1) {
        delete boxShamType.coinId
      }
      const url = this.shamId ? '/dq_admin/boxShamType/edit' : '/dq_admin/boxShamType/add'
      this.isLoading = true
      const res = await this.axios.post(
          url,
          this.shamId ? boxShamType : sendObj
      );
      this.isLoading = false
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success");
    },
    resetForms() {
      this.params = {
        id: undefined,
        // 假币类型
        shamType: undefined,
        // 假币名称
        shamName: undefined,
        // 图片
        images: undefined,
        // 币种ID
        coinId: undefined,
        // 号段
        boxCode: undefined,
        // 评级公司
        boxCompanyType: undefined,
        // 描述
        remark: undefined,
        // 批量假号段
        codeBatchMatch: undefined,
        // 批量假评级公司
        companyType: undefined
      }
      this.show = false
    },
    /** 获取大币种列表 */
    async searchData(inner){
      if (!inner) return
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const {records} = res.data
      this.bigCoinList = records
    },
    /** 切换假币类型 */
    handleChangeShamType(e) {
      this.resetForms()
    }
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
